import { useState } from "react";
import StyledInput from "./StyledInput.styles";

const Input = ({
  type = "text",
  label = "",
  required = false,
  error = false,
  message = "",
  placeholder = "",
  defaultValue,
  value,
  onChange,
  disabled,
  inputValid,
  iconLeft,
  id,
  data,
}) => {
  const [inputType, setInputType] = useState(type);

  const toggleShow = (e) => {
    e.preventDefault();
    setInputType(inputType === "password" ? "text" : "password");
  };
  return (
    <StyledInput
      error={error}
      inputValid={inputValid}
      iconLeft={iconLeft}
      disabled={disabled}
    >
      <fieldset className="input-fieldset">
        {label && <legend className="label">{label}</legend>}
        <div className="input-field-container">
          {iconLeft && <div className="icon-left">{iconLeft}</div>}
          <input
            className="input-field"
            type={inputType}
            required={required}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={(e) => onChange && onChange(e, data)}
            disabled={disabled}
            id={id}
          />

          {type === "password" && (
            <button className="toggle-type-button" onClick={toggleShow}>
              {inputType === "password" ? "Show" : "Hide"}
            </button>
          )}
        </div>
      </fieldset>
      {error && <p className="error-message">{message}</p>}
    </StyledInput>
  );
};

export default Input;
