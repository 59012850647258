import { useEffect } from "react";
import "./App.css";
import "./utils/utilities.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import Router from "./router";
import { useAuth } from "./context/auth";
import { crushAllTokens, getToken } from "./utils/manageToken";
import constants from "./utils/constants";
import actionTypes from "./context/auth/actionTypes";
import { differenceInMilliseconds } from "date-fns";
import { setTimeoutForAction } from "./utils/helpers/timer";
import { useNavigate } from "react-router-dom";
import ReactGA4 from "react-ga4";
import { isProductionGoogleAnalyticsEnvironment } from "./utils/helpers";

function App() {
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isProductionGoogleAnalyticsEnvironment()) {
      ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  useEffect(() => {
    getToken(constants.PLUG_TOKEN)
      ? dispatch({
          type: actionTypes.LOGIN,
          payload: JSON.parse(getToken(constants.PLUG_USER_INFO)),
        })
      : dispatch({ type: actionTypes.LOGOUT });
    const expirationTime = getToken(constants.PLUG_TOKEN_EXPIRATION_TIME);

    if (expirationTime) {
      const timeDifferenceInMilliSeconds = differenceInMilliseconds(
        new Date(expirationTime),
        new Date()
      );
      setTimeoutForAction(() => {
        dispatch({
          type: actionTypes.LOGOUT,
        });
        crushAllTokens();
        navigate("/login");
      }, timeDifferenceInMilliSeconds);
    }
  }, [dispatch]);

  return <Router />;
}

export default App;
