import styled from "styled-components";

const StyledTextArea = styled.div`
  .textarea {
    display: block;
    width: 100%;
    padding: 22px;
    border: ${({ error }) =>
      error ? "1px solid red" : "1px solid rgba(51, 51, 51, 0.2)"};
    border-radius: 10px;
  }

  .textarea-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 16px;
  }

  .error-message {
    display: block;
    color: ${({ theme }) => theme.colors.plugRed};
    margin: 0;
  }
`;

export default StyledTextArea;
