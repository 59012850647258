export const breakpoints = {
  mobile: 576,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1024,
  extraLargeDesktop: 1200,
};

export const mediaQueries = (breakpointKey, endBreakpointKey) => {
  if (breakpointKey === "mobile" && !endBreakpointKey) {
    return (style) =>
      `@media only screen and (max-width: ${breakpoints[breakpointKey]}px) { ${style} }`;
  }

  if (breakpointKey === "mobile" && endBreakpointKey) {
    return (style) =>
      `@media only screen and (max-width: ${breakpoints[endBreakpointKey]}px) { ${style} }`;
  }

  if (endBreakpointKey) {
    return (style) =>
      `@media only screen and (min-width: ${
        breakpoints[breakpointKey]
      }px) and (max-width: ${
        breakpoints[endBreakpointKey] - 1
      }px) { ${style} }`;
  }

  return (style) =>
    `@media (min-width: ${breakpoints[breakpointKey]}px) { ${style} }`;
};
