import StyledRadio from "./StyledRadio";

const Radio = ({
  disabled,
  flex = true,
  label,
  name,
  onSelect,
  defaultChecked,
  id = "radio-input",
  value,
}) => {
  return (
    <StyledRadio flex={flex}>
      <span className="radio-input-container">
        <input
          type="radio"
          id={id}
          className="radio-input"
          name={name || ""}
          defaultChecked={defaultChecked}
          value={value}
          onChange={onSelect}
        />
      </span>
      <label htmlFor={id} className="radio-label">
        {label}
      </label>
    </StyledRadio>
  );
};

export default Radio;
