import StyledTextArea from "./StyledTextarea";

const Textarea = ({
  label,
  value,
  placeholder,
  onChange,
  id,
  name,
  rows = "8",
  col,
  disabled = false,
  error = false,
  message,
  labelClassName,
}) => {
  return (
    <StyledTextArea error={error}>
      {label && (
        <label htmlFor={id} className={`textarea-label ${labelClassName}`}>
          {label}
        </label>
      )}
      <textarea
        className="textarea"
        value={value}
        col={col}
        rows={rows}
        onChange={onChange}
        id={id}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
      ></textarea>
      {error && <span className="error-message">{message}</span>}
    </StyledTextArea>
  );
};

export default Textarea;
