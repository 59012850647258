import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CookiesProvider } from "react-cookie";
import App from "./App";
import StyledThemeProvider from "./theme";
import { AuthProvider } from "./context/auth";
import { ToastContainer } from "./lib";
import ScrollToTop from "./utils/ScrollToTop";
import { HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledThemeProvider>
        <BrowserRouter>
          <AuthProvider>
            <ToastContainer>
              <CookiesProvider defaultSetOptions={{ path: "/" }}>
                <HelmetProvider>
                  <ScrollToTop />
                  <App />
                </HelmetProvider>
              </CookiesProvider>
            </ToastContainer>
          </AuthProvider>
        </BrowserRouter>
      </StyledThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
