import { useState } from "react";
import StyledCheckbox from "./StyledCheckbox";
import { ReactComponent as CheckMark } from "../../assets/icons/plug-checkmark.svg";

const Checkbox = ({
  children,
  defaultCheked,
  onChange,
  disabled,
  name,
  value,
  data,
}) => {
  const [checked, setChecked] = useState(false || defaultCheked);
  const handleClicked = () => {
    if (!disabled) {
      setChecked(!checked);
      onChange && onChange({ name, value, checked: !checked, data });
    }
  };

  return (
    <StyledCheckbox checked={checked} disabled={disabled}>
      <button className="check-box-button" onClick={handleClicked}>
        <div className="check-mark-icon">
          <CheckMark />
        </div>
        <input type="checkbox" />
        <span className="custom-check"></span>
        <label>{children}</label>
      </button>
    </StyledCheckbox>
  );
};

export default Checkbox;
