import styled from "styled-components";

const StyledTable = styled.div`
  width: 100%;
  overflow-x: scroll;
  .table {
    width: 100%;
    border-spacing: 0;

    th,
    td {
      border: 1px solid #f4f4f4;
      padding: 1rem;
      text-align: left;
      font-size: 1.1rem;
    }
    td {
      font-size: 1rem;
    }
  }
`;

export default StyledTable;
