import styled from "styled-components";

const StyledFileInput = styled.div`
  .label {
    background: #dbdbdb;
    border: 1px solid #bdbdbd;
    padding: 22px;
    margin: 0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: block;
    border: 1px solid
      ${({ theme, error, inputValid }) =>
        error
          ? theme.colors.plugRed
          : inputValid
          ? theme.colors.plugDarkYellow
          : theme.colors.plugGray};
  }

  .error-message {
    color: ${({ theme }) => theme.colors.plugRed};
    margin: 0;
  }
`;

export default StyledFileInput;
