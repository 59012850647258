import styled from "styled-components";

const StyledToastContainer = styled.div`
  .Toastify__toast {
    padding: 0;
    cursor: unset;
    width: fit-content;
    border-radius: 10px;
  }

  .Toastify__toast-body {
    * {
      color: ${({ theme }) => theme.colors.plugWhite};
    }
    min-width: 250px;
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.plugLightGreen};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.plugRed};
  }
`;

export default StyledToastContainer;
