import styled, { css } from "styled-components";
import { mediaQueries } from "../../utils/mediaQueries";

export const StyledSelect = styled.div`
  .select-container {
    background: ${(props) =>
      props.backgroundColor
        ? props.backgroundColor
        : props.theme.colors.plugWhite};
    border: ${(props) =>
      props.borderNone
        ? "0"
        : props.error
        ? `1px solid ${props.theme.colors.plugRed}`
        : "1px solid rgba(51, 51, 51, 0.2)"};
    border-radius: 10px;
    position: relative;
    min-width: ${(props) => (props.width ? "" : "222px")};
    ${(props) =>
      props.width &&
      css`
        width: ${(props) => props.width};
      `}
  }

  label {
    position: absolute;
    top: -10px;
    left: 10px;
  }

  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background: transparent;
    padding: ${(props) =>
      props.buttonCustomPadding ? props.buttonCustomPadding : "22px 10px"};
    cursor: pointer;
  }

  .selected-value {
    color: ${(props) =>
      props.placeholderSelected ? "#BDBDBD" : props.theme.colors.plugBlack};
    white-space: nowrap;
    width: 80%;
    text-align: left;
    overflow: hidden;
  }
  .dropdown-icon {
    width: 15%;
    text-align: right;
  }
  .error-message {
    color: ${({ theme }) => theme.colors.plugRed};
    margin: 0;
  }

  ${mediaQueries("mobile")`
    button {
      padding: 15px 5px 15px 15px;
    }
  `}

  ${mediaQueries('tablet', 'desktop')`
    button {
      padding: 15px 5px 15px 15px;
    }
  `}
`;
