import { useState, useRef } from "react";
import { StyledAccordion, StyledAccordionItem } from "./StyledAccordion.styles";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";

const Accordion = ({ flex, children }) => {
  return <StyledAccordion flex={flex}>{children}</StyledAccordion>;
};

const AccordionItem = ({ titleIcon, title, children, width, arrowIcon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");
  const [rotate, setRotate] = useState("rotate(0deg)");

  const content = useRef(null);

  const handleExpand = () => {
    setIsOpen(!isOpen);
    setContentHeight(isOpen ? "0px" : `${content.current.scrollHeight + 10}px`);
    setRotate(isOpen ? "rotate(0deg)" : "rotate(180deg)");
  };

  return (
    <StyledAccordionItem
      width={width}
      isOpen={isOpen}
      contentHeight={contentHeight}
      rotate={rotate}
    >
      <button className="accordion-button" onClick={handleExpand}>
        <p className="accordion-title">
          {titleIcon && <span className="title-icon">{titleIcon}</span>}
          {title}
        </p>
        <div className="accordion-icon">
          {arrowIcon ? arrowIcon : <ArrowDown />}
        </div>
      </button>
      <div ref={content} className="accordion-content">
        {children}
      </div>
    </StyledAccordionItem>
  );
};

export { Accordion, AccordionItem };
