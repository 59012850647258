import styled from "styled-components";

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;

  .check-mark-icon {
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
    path {
      fill: ${({ disabled, checked, theme }) =>
        disabled && checked ? theme.colors.plugGray : "default"};
    }
  }

  .check-box-button {
    position: relative;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    border: 0;
    padding: 0;
    background: transparent;
    display: flex;
    align-items: center;

    label {
      margin-left: 10px;
      max-width: 90%;
      text-align: left;
      line-height: 24px;
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      color: ${({ disabled, checked, theme }) =>
        disabled && checked ? theme.colors.plugGray : "default"};
    }
  }

  input {
    visibility: hidden;
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    overflow: hidden;
  }

  .custom-check {
    height: 18px;
    width: 20px;
    border: 1px solid grey;
    border-radius: 5px;
    display: inline-block;
  }

  [type="checkbox"]:checked + span {
    /* background: black; */
  }
`;

export default StyledCheckbox;
