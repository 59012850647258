import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StyledToastContainer from "./StyledToastContainer.styles";

const Toast = ({ children }) => {
  return (
    <StyledToastContainer>
      {children}
      <ToastContainer
        icon={false}
        autoClose={false}
      />
    </StyledToastContainer>
  );
};

export default Toast;
