import styled, { css } from "styled-components";

const selectableMixins = css`
  background: ${({ isActive }) => (isActive ? "#EF1842" : "#fff")};
  border: 1px solid ${({ isActive }) => (isActive ? "#EF1842" : "#e0e0e0")};
  color: ${({ isActive }) => (isActive ? "#fff" : "#333")};
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${({ isActive }) => (isActive ? "#ee355a" : "#fde0b5")};
    border: 1px solid ${({ isActive }) => (isActive ? "#EF1842" : "#fde0b5")};
    color: ${({ isActive }) => (isActive ? "#fff" : "#333")};
  }
`;

const StyledPill = styled.div`
  width: ${({ width }) => (width ? width : "fit-content")};
  text-align: left;
  font-size: 16px;
  margin: 0;
  border-radius: 20px;
  padding: ${(props) => (props.customPadding ? props.customPadding : "20px")};
  background: ${({ theme }) => theme.colors.plugWhite};
  border: 1px solid #e0e0e0;
  transition: 0.3s ease-in-out;

  ${({ selectable }) => selectable && selectableMixins}
`;

export default StyledPill;
