import React from "react";
import { Link } from "react-router-dom";
import StyledBreadCrumb from "./StyledBreadCrumb.styles";

const BreadCrumb = ({ items, seperator = "/" }) => {

  return (
    <StyledBreadCrumb>
      {items.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <span
              className={`crumb-item ${
                index === items.length - 1 && "crumb-item-end"
              }`}
            >
              <Link to={item?.href}>{item.name}</Link>
            </span>
            {index !== items.length - 1 && (
              <span className="seperator">{seperator}</span>
            )}
          </React.Fragment>
        );
      })}
    </StyledBreadCrumb>
  );
};

export default BreadCrumb;
