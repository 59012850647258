import styled from "styled-components";

const StyledBreadCrumb = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  .crumb-item {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    a {
      color: ${({ theme }) => theme.colors.plugRed};
      text-decoration: none;
      text-transform: capitalize;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .crumb-item-end {
    cursor: text;
    a {
      color: #bdbdbd;
      text-decoration: none;
      cursor: auto;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .seperator {
    color: ${({ theme }) => theme.colors.plugBlack};
    display: inline-block;
    margin: 0 8px;
  }
`;

export default StyledBreadCrumb;
