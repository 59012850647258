import styled from "styled-components";

const StyledCustomDatePicker = styled.div`
  .datepicker-label {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .datepicker-error-message {
    color: ${({ theme }) => theme.colors.plugRed};
    margin: 0;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-component {
    .react-datepicker-input {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent !important;
      border: 1px solid
        ${({ theme, error }) =>
          error ? theme.colors.plugRed : theme.colors.plugGray};
      border-radius: 10px;
      text-align: start;
      height: unset;

      input {
        display: block;
        padding: 22px 10px;

        &::placeholder {
          color: ${({ theme }) => theme.colors.plugGray};
          font-weight: normal;
        }
      }
    }
  }
`;

export default StyledCustomDatePicker;
