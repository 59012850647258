import { useState } from "react";
import { StyledSelect } from "./StyledSelect.styles";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import DropdownList from "./DropdownList";
import useOutsideClick from "../../utils/customHooks/useClickOutside";

const Select = ({
  label,
  placeholder = "Select option",
  options = [],
  onSelect,
  disabled,
  defaultSelected,
  selectButton,
  backgroundColor,
  width,
  buttonCustomPadding,
  borderNone,
  error,
  message,
  data,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [placeholderSelected, setPlaceholderSelected] = useState(
    !defaultSelected?.label
  );
  const [selectedOption, setSelectedOption] = useState(() => {
    return defaultSelected?.label
      ? defaultSelected
      : {
          value: "",
          label: placeholder,
          id: "",
        };
  });
  const ref = useOutsideClick(() => setIsOpen(false));

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setPlaceholderSelected(false);
    onSelect && onSelect(option, data);
  };

  return (
    <StyledSelect
      placeholderSelected={placeholderSelected}
      backgroundColor={backgroundColor}
      width={width}
      buttonCustomPadding={buttonCustomPadding}
      borderNone={borderNone}
      ref={ref}
      error={error}
    >
      <div className="select-container">
        {label && <label>{label}</label>}
        {selectButton ? (
          selectButton
        ) : (
          <div>
            <button disabled={disabled} onClick={() => setIsOpen(!isOpen)}>
              <span className="selected-value">{selectedOption.label}</span>
              <span className="dropdown-icon">
                <ChevronDown />
              </span>
            </button>
          </div>
        )}
        {isOpen && (
          <DropdownList options={options} handleSelect={handleSelect} />
        )}
      </div>
      {error && <p className="error-message">{message}</p>}
    </StyledSelect>
  );
};

export default Select;
