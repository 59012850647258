import StyledDropdownList from "./StyledDropdownList.styles";
const DropdownList = ({ handleSelect, options }) => {
  return (
    <StyledDropdownList>
      {options.map((option) => (
        <li
          key={option.id}
          onClick={() =>
            handleSelect({
              ...option,
              value: option.value,
              label: option.label,
              id: option.id,
            })
          }
        >
          <span className="dropdown-item">{option.label}</span>
        </li>
      ))}
    </StyledDropdownList>
  );
};

export default DropdownList;
