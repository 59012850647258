import { useState } from "react";
import StyledPill from "./StyledPill";

const Pill = ({
  children,
  customPadding,
  clickHandler,
  id,
  selectable = false,
  width,
  defaultSelected = false,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(defaultSelected || false);
  const handleClick = () => {
    if (selectable) {
      setIsActive(!isActive);
      selectable &&
        clickHandler &&
        clickHandler({ id, isActive: !isActive, ...rest });
    }
  };

  return (
    <StyledPill
      customPadding={customPadding}
      onClick={handleClick}
      isActive={isActive}
      selectable={selectable}
      width={width}
    >
      {children}
    </StyledPill>
  );
};

export default Pill;
