import ACTIONS from "./actionTypes";

export const initialState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  image: null,
  isLoggedIn: false,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOGIN:
    case ACTIONS.UPDATE:
      const { id, firstName, lastName, email, image } = action.payload;
      return {
        ...state,
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        image: image,
        isLoggedIn: true,
      };

    case ACTIONS.LOGOUT:
      return {
        ...state,
        ...initialState,
      };

    default:
      throw new Error(`action type: ${action.type} may not exist`);
  }
};

export default authReducer;
