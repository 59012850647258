import styled, { css } from "styled-components";
import { mediaQueries } from "../../utils/mediaQueries";

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  border-radius: 10px;
  padding: 15px 0;
  background: transparent;
  color: ${(props) => props.theme.colors.plugBlack};
  cursor: pointer;
  width: ${(props) => props.width};
  height: 100%;

  .icon-right {
    margin-left: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-left {
    margin-right: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${(props) =>
    props.primary &&
    css`
      background: ${(props) =>
        props.disabled ? "#EC5774" : props.theme.colors.plugRed};
      border: 1px solid ${(props) => props.theme.colors.plugRed};
      color: ${(props) => props.theme.colors.plugWhite};
      ${(props) => props.disabled && "cursor: not-allowed; border: none;"}
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: ${(props) => props.theme.colors.plugDarkYellow};
      border: 0;
      color: ${(props) => props.theme.colors.plugWhite};
      border: 1px solid ${(props) => props.theme.colors.plugDarkYellow};
    `}

  ${(props) =>
    props.primary &&
    props.outline &&
    css`
      background: transparent;
      border: 1px solid ${(props) => props.theme.colors.plugRed};
      color: #000;
      ${(props) => props.disabled && "cursor: not-allowed; border: 0;"}
    `};

  ${(props) =>
    props.secondary &&
    props.outline &&
    css`
      background: transparent;
      color: #000;
      border: 1px solid ${(props) => props.theme.colors.plugDarkYellow};
    `};

  ${mediaQueries("mobile")`
    width: 100%;
  `}
`;

export default StyledButton;
