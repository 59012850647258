import styled from "styled-components";

const StyledDropdownList = styled.ul`
  position: absolute;
  background: ${(props) => props.theme.colors.plugWhite};
  list-style: none;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 100%;
  padding: 23px 16px;
  border: 1px solid #e0e0e0;
  z-index: 1;
  max-height: 219px;
  overflow-y: auto;

  li {
    border-radius: 6px;
    cursor: pointer;

    .dropdown-item {
      display: block;
      padding: 10px 5px;
      border-bottom: 1px solid rgba(51, 51, 51, 0.2);
      width: 100%;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: ${(props) => props.theme.colors.plugLightYellow};
    }
  }

  .dropdown-item {
    width: 100%;
  }
`;

export default StyledDropdownList;
