import { Suspense, lazy, useEffect } from "react";
import ReactGA4 from "react-ga4";
import { Routes, Route } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import Fallback from "../utils/Fallback";
import ErrorBoundary from "../utils/ErrorBoundary";
import { isProductionGoogleAnalyticsEnvironment } from "../utils/helpers";

const LandingPage = lazy(() => import("./publicRoutes/Landing"));
const Login = lazy(() => import("./publicRoutes/Login"));
const Signup = lazy(() => import("./publicRoutes/Signup"));
const Resources = lazy(() => import("./publicRoutes/Resources"));
const Guides = lazy(() => import("./publicRoutes/Guides"));
const TermsAndConditions = lazy(() =>
  import("./publicRoutes/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("./publicRoutes/PrivacyPolicy"));
const AboutUs = lazy(() => import("./publicRoutes/AboutUs"));
const Careers = lazy(() => import("./publicRoutes/Careers"));
const ContactUs = lazy(() => import("./publicRoutes/ContactUs"));
const ForgotPassword = lazy(() => import("./publicRoutes/ForgotPassword"));
const SearchResults = lazy(() => import("./publicRoutes/SearchResults"));
const BuyPlan = lazy(() => import("./publicRoutes/BuyPlan"));
const Notifications = lazy(() => import("./publicRoutes/Notifications"));
const HospitalList = lazy(() => import("./publicRoutes/HospitalList"));
const MyPlans = lazy(() => import("./privateRoutes/MyPlans"));
const Claims = lazy(() => import("./privateRoutes/Claims"));
const Settings = lazy(() => import("./privateRoutes/Settings"));
const NotFound = lazy(() => import("./publicRoutes/NotFound"));

function Router() {
  useEffect(() => {
    if (isProductionGoogleAnalyticsEnvironment()) {
      ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route path="/login/*" element={<Login />} />
          <Route path="/register/*" element={<Signup />} />
          <Route path="/reset-password/*" element={<ForgotPassword />} />
          <Route path="/*" element={<LandingPage />} />

          <Route path="/resources/*" element={<Resources />} />
          <Route path="/about-us/*" element={<AboutUs />} />
          <Route path="/careers/*" element={<Careers />} />
          <Route
            path="/terms-and-conditions/*"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />
          <Route path="/contact-us/*" element={<ContactUs />} />
          <Route path="/guides/*" element={<Guides />} />
          <Route path="/buy-plan/*" element={<BuyPlan />} />
          <Route path="/list-plans/*" element={<SearchResults />} />
          <Route path="/notifications/*" element={<Notifications />} />
          <Route path="/hospital-list/*" element={<HospitalList />} />
          <Route element={<AuthGuard />}>
            <Route path="/my-plans/*" element={<MyPlans />} />
            <Route path="/claims/*" element={<Claims />} />
            <Route path="/settings/*" element={<Settings />} />
          </Route>
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default Router;
