const constants = {
  PLUG_USER_INFO: "PLUG_USER_INFO",
  PLUG_TOKEN: "PLUG_TOKEN",
  PLUG_TOKEN_EXPIRATION_TIME: "PLUG_TOKEN_EXPIRATION_TIME",
  SELECTED_PLAN_INFO: "SELECTED_PLAN_INFO",
  QUOTE_SEARCH_PARAMETER: "QUOTE SEARCH PARAMETER",
  QUOTE_PURCHASE_INPUT: "QUOTE PURCHASE INPUT",
  OTP_REFERENCE: "OTP_REFERENCE",
  SELECTED_POLICY_TO_RENEW: "SELECTED_POLICY_TO_RENEW",
  PLUG_INSURANCE_CATEGORIES: "PLUG_INSURANCE_CATEGORIES",
  DISPLAY_TYPE: "displayType",
};

export default constants;
