import React from "react";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import StyledCustomDatePicker from "./StyledCustomDatePicker.styles";

const CustomDatePicker = ({
  placeholder = "DD/MM/YYYY",
  defaultSelectedDate,
  onChange,
  data,
  error,
  message,
}) => {
  return (
    <StyledCustomDatePicker>
      <DatePickerInput
        onChange={(e) => onChange(e, data)}
        placeholder={placeholder}
        defaultValue={defaultSelectedDate}
        className="date-picker-input"
      />
      {error && <span className="datepicker-error-message">{message}</span>}
    </StyledCustomDatePicker>
  );
};

export default CustomDatePicker;
