import { useEffect } from "react";
import { createPortal } from "react-dom";
import StyledModal from "./StyledModal.styles";

const Modal = ({ show, children, onClose }) => {
  useEffect(() => {
    show && (document.body.style.overflow = "hidden");
    !show && (document.body.style.overflow = "unset");
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return createPortal(
    <StyledModal>
      <button className="close-modal" onClick={() => onClose && onClose()}>
        X
      </button>
      <div className="modal-container">{children}</div>
    </StyledModal>,
    document.body
  );
};

export default Modal;
