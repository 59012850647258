import styled from "styled-components";
import { ReactComponent as Spinner } from "../assets/icons/spinner-solid.svg";

const StyledFallback = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    width: 50px;
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Fallback = () => {
  return (
    <StyledFallback>
      <Spinner className="spinner" />
    </StyledFallback>
  );
};

export default Fallback;
