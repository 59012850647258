import styled, { css } from "styled-components";
import { mediaQueries } from "../../utils/mediaQueries";

const StyledAccordion = styled.div`
  ${({ flex }) =>
    flex
      ? css`
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          flex-direction: row;
        `
      : css`
          display: block;
        `};
  width: 100%;

  ${mediaQueries("mobile")`
    display: block;
  `}
`;

const StyledAccordionItem = styled.div`
  padding: 0.5rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.plugWhite};
  width: ${({ width }) => width || "100%"};
  margin: 1rem 0;

  .accordion-icon {
    transition: transform 0.6s ease;
    transform: ${({ rotate }) => rotate};
  }

  .accordion-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0;
  }

  .accordion-title {
    max-width: 95%;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .accordion-content {
    transition: height 0.6s ease;
    overflow: hidden;
    height: ${(props) => props.contentHeight || "0"};
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: "EuclideSquareLight";
  }

  .title-icon {
    display: inline-block;
    margin-right: 10px;
  }

  ${mediaQueries("mobile")`
    width: 100%;
    
    .accordion-icon {
      margin-left: 10px;
    } 
  `}
`;

export { StyledAccordion, StyledAccordionItem };
