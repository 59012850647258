import styled from "styled-components";
import { mediaQueries } from "../../utils/mediaQueries";

const StyledModal = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 999;

  .close-modal {
    position: fixed;
    top: 42px;
    right: 24px;
    border: 1px solid ${({ theme }) => theme.colors.plugGray};
    color: #000;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
    background: #fafafa;
  }

  .modal-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  ${mediaQueries('mobile')`
    .modal-container {
      width: 100%;
    }
  `}
`;

export default StyledModal;
