import { useState } from "react";
import StyledFileInput from "./StyledFileInput.styles";

const FileInput = ({
  id,
  onChange,
  allowedExtensions = "*",
  placeholderText = "Select a file to upload",
  error,
  message,
  data,
  defaultFileName,
}) => {
  const [fileName, setFileName] = useState(null);

  const handleFileSelected = (e) => {
    if (allowedExtensions !== "*") {
      const extensions = allowedExtensions.split(" ");
      const selectedFileExtension =
        e.target.files[0].name.split(".")[
          e.target.files[0].name.split(".").length - 1
        ];
      if (extensions.includes(`.${selectedFileExtension}`)) {
        setFileName(e.target.files[0].name);
        onChange(e, data);
      }
    } else {
      setFileName(e.target.files[0].name);
      onChange(e, data);
    }
  };

  return (
    <StyledFileInput error={error}>
      <label htmlFor={id} className="label">
        {fileName || defaultFileName || placeholderText}
      </label>
      <input
        type="file"
        allow={allowedExtensions}
        hidden
        onChange={handleFileSelected}
        id={id}
      />
      {error && <p className="error-message">{message}</p>}
    </StyledFileInput>
  );
};

export default FileInput;
