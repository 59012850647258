import styled from "styled-components";
import { mediaQueries } from "../../utils/mediaQueries";

const StyledInput = styled.div`
  .input-fieldset {
    background: ${({ theme, disabled }) =>
      disabled ? "#FAFAFA" : theme.colors.plugWhite};
    border: 1px solid
      ${({ theme, error, inputValid }) =>
        error
          ? theme.colors.plugRed
          : inputValid
          ? theme.colors.plugDarkYellow
          : theme.colors.plugGray};
    padding: 22px 10px;
    margin: 0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  .input-field {
    width: ${({ iconLeft }) => (iconLeft ? "70%" : "100%")};
    border: 0;
    outline: none;
    color: ${({ theme, disabled }) =>
      disabled ? "#BDBDBD" : theme.colors.plugBlack};
    background: transparent;
    
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bdbdbd;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bdbdbd;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bdbdbd;
    }
  }
  .label {
    margin-left: -8px;
    padding: 0 10px;
  }
  .error-message {
    color: ${({ theme }) => theme.colors.plugRed};
    margin: 0;
  }
  .input-field-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  .toggle-type-button {
    position: absolute;
    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.colors.plugRed};
    cursor: pointer;
    right: 0;
  }
  .icon-left {
    display: inline-block;
    margin-right: 13px;
    width: 20px;
    height: 20px;
  }

  ${mediaQueries("mobile")`
    .input-fieldset {
      padding: 13px 5px 15px 15px;
    }

    .label {
      font-size: 10px;
    }

    .input-field-container {
      .input-field {
        font-size: 100%;
      }
    }

    .toggle-type-button {
      font-size: 100%;
    }
  `}

  ${mediaQueries("tablet", "desktop")`
    .input-fieldset {
      padding: 13px 5px 15px 15px;
    }
  `}
`;

export default StyledInput;
