import styled, { css } from "styled-components";
import { mediaQueries } from "../../utils/mediaQueries";

const StyledRadio = styled.div`
  display: ${({ flex }) => (flex ? "flex" : "block")};

  .radio-input-container {
    display: inline-block;
    position: relative;
  }

  .radio-input {
    appearance: none;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.colors.plugGray};
    background: #fafafa;
    border-radius: 50%;
    ::after {
      content: "";
      display: block;
      position: absolute;
      top: 25%;
      left: 30%;
      border-radius: 50%;
      width: 7px;
      height: 7px;
    }

    :checked {
      background: ${({ theme }) => theme.colors.plugRed};
      ::after {
        background-color: ${({ theme }) => theme.colors.plugWhite};
      }
    }
  }

  .radio-label {
    ${({ flex }) =>
      flex
        ? css`
            margin-left: 24px;
          `
        : css`
            margin-left: 0;
            display: block;
          `};
  }

  ${mediaQueries ("tablet")`
     .radio-label {
      margin-left: 5px;
    }
  `}
`;

export default StyledRadio;
