import React from "react";
import StyledButton from "./StyledButton";

const Button = ({
  onClick,
  primary = true,
  secondary,
  isLoading,
  outline,
  iconLeft,
  iconRight,
  disabled,
  width,
  height,
  children,
}) => {
  return (
    <StyledButton
      primary={primary}
      secondary={secondary}
      outline={outline}
      onClick={onClick}
      disabled={disabled || isLoading}
      width={width}
      height={height}
    >
      {iconLeft && <span className="icon-left">{iconLeft}</span>}
      {isLoading ? "Please wait..." : children ? children : "Button"}
      {iconRight && <span className="icon-right">{iconRight}</span>}
    </StyledButton>
  );
};

export default Button;
