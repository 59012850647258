import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    plugRed: "#EF1842",
    plugLightYellow: "#FEF5E6",
    plugDarkYellow: "#F9A11E",
    plugBlack: "#333333",
    plugGray: "#BDBDBD",
    plugGrayBorder: "#E0E0E0",
    plugWhite: "#FFFFFF",
    plugLightGreen: "#27AE60",
    plugDarkGreen: "#219653",
  },
};

const StyledThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledThemeProvider;
