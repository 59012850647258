import { Navigate, Outlet } from "react-router-dom";
import constants from "../utils/constants";
import { getToken } from "../utils/manageToken";

const AuthGuard = () => {

  return getToken(constants.PLUG_TOKEN) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default AuthGuard;
